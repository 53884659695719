import * as React from 'react';

// eslint-disable-next-line max-len
export const path = 'm41.851 0c-23.113 0-41.851 18.738-41.851 41.851 0 23.114 18.738 41.853 41.851 41.853h19.631v32.591h-19.631c-23.113 0-41.851 18.738-41.851 41.853 0 23.114 18.738 41.851 41.851 41.851 23.114 0 41.853-18.737 41.853-41.851v-19.629h32.591v19.629c0 23.114 18.738 41.851 41.853 41.851 23.114 0 41.851-18.737 41.851-41.851 0-23.114-18.737-41.853-41.851-41.853h-19.629v-32.591h19.629c23.114 0 41.851-18.738 41.851-41.853 0-23.113-18.737-41.851-41.851-41.851-23.115 0-41.853 18.738-41.853 41.851v19.631h-32.591v-19.631c0-23.113-18.738-41.851-41.853-41.851zm0 22.222c10.842 0 19.631 8.7878 19.631 19.629v19.631h-19.631c-10.842 0-19.629-8.7893-19.629-19.631 0-10.842 8.7878-19.629 19.629-19.629zm116.29 0c10.842 0 19.629 8.7878 19.629 19.629 0 10.842-8.7878 19.631-19.629 19.631h-19.629v-19.631c0-10.842 8.7886-19.629 19.629-19.629zm-74.446 61.482h32.591v32.591h-32.591zm-41.853 54.815h19.631v19.629c0 7.9388-4.7841 15.097-12.118 18.135-7.3358 3.0393-15.779 1.3605-21.393-4.2541-5.6138-5.613-7.2924-14.059-4.2541-21.393 3.0378-7.3358 10.196-12.117 18.135-12.117zm96.668 0h19.629c10.842 0 19.629 8.7886 19.629 19.629 0 10.84-8.7878 19.629-19.629 19.629-10.84 0-19.629-8.7886-19.629-19.629z';

interface ILogoProps {
    size: number;
    className?: string;
}

const Logo: React.FC<ILogoProps> = ({ size, className = '' }: ILogoProps) => (
    <svg
        className={className}
        width={`${size}rem`}
        height={`${size}rem`}
        viewBox="0 0 200 200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <path fill="#fff" d={path} />
    </svg>
);

export default Logo;
